import $ from 'jquery';


/**
 * 
 * NOTES:
 * Some images are delivered as SVG, which should not be displayed.
 * Only possibility to get content type of image in a fetch response header.
 * @urb:
 * - set small height or hide?
 * -- external images => CORS!!!
 * - handle svg generally or use filename 'filetype-not-supported.svg' as convention?
 * 
 */

const ProductInformation = (() => {

    const MODAL_SELECTOR = ".paragraph-product-information img";
    let $images;

    const initialize = () => {

        $images = $(MODAL_SELECTOR);

        if ($images.length) {

            $images.each((idx, img) => {

                loadImage(img);
            })
        }
    }

    /**
     * load images per ajax to determine their content-type from the response.
     * hide image on type svg
     * 
     * @param {*} img image dom element
     */
    const loadImage = async (img) => {
        
        try {

            // do only when data-src attribute set
            if (img.dataset.src) {

                const response = await fetch(img.dataset.src);
                const type = response.headers.get('content-type')
                console.debug(type);
    
                const blob = await response.blob();
                const src = URL.createObjectURL(blob);
                
                // hide img tag on svg
                if (type == 'image/svg+xml') {
                    $(img).hide();
                } else {
                    // set img src to blob
                    img.src = src;
                    $(img).show();
                }
                
            } else {
                $(img).show();
            }


        } catch (error) {
            console.error(error);
        }

    }

    return {
        init: initialize
    }

})($);

export default ProductInformation;