import $ from 'jquery';
import 'jquery-once';
import DataTable from 'datatables.net-zf';
import API from './api';
import FormElement from './form-element';


/**
 * Downloads table using datatables
 * 
 * https://datatables.net/reference/
 * 
 * table elements with class .table-js-sort are initialized with datatable
 * 
 * TODO:  
 * - @urb filetype container in markup möglich?
 * x- show more data-t attributes
 */
const DownloadsTable = (function () {
  'use strict';


  // for more datatables options visit https://datatables.net/reference/option/
  let defaultOptions = {
    dom: 'rt',
    lengthMenu: [10], // no. of entries displayed
    columnDefs: [
      { type: 'file-size', targets: 2 }
    ],
    search: {
      regex: true
    }
  };

  // ordering impl. for filesize
  $.fn.dataTable.ext.type.order['file-size-pre'] = (data) => {
    if (data === null || data === '') {
      return 0;
    }

    const matches = data.match(/^(\d+(?:\.\d+)?)\s*([a-z]+)/i);
    const multipliers = {
      b: 1,
      bytes: 1,
      kb: 1000,
      kib: 1024,
      mb: 1000000,
      mib: 1048576,
      gb: 1000000000,
      gib: 1073741824,
      tb: 1000000000000,
      tib: 1099511627776,
      pb: 1000000000000000,
      pib: 1125899906842624
    };

    if (matches) {
      const multiplier = multipliers[matches[2].toLowerCase()];
      return parseFloat(matches[1]) * multiplier;
    } else {
      return -1;
    };
  };



  const initializeDatatables = () => {
    let $table = $('.table-js-sort');

    $table.each(function (i) {

      let $currentTable = $(this);

      $currentTable.once().DataTable({
        ...defaultOptions,


        /**
         * Create file-type filter dropdown
         * Callback on 'Editor initialisation complete' event
         * https://editor.datatables.net/reference/event/initComplete
         */
        initComplete: function () {

          this.api().columns('.views-field-secondary-file-category, .file-type').every(function (i) {
            let $column = this;
            let $colHeader = $($column.header());
            let checkedValues = [];
            let searchString = [];
            let $container = [];
            let filterOptions = [];
            filterOptions[i] = [];

            // Add checkboxes to filter table content, filter function
            /* $container[i] = $('<div class="dt-checkbox-container hidden"></div>') */
            $container[i] = $currentTable.parent().prev()
              .appendTo($colHeader)
              .on('change', 'input:checkbox', function (e) {
                // Array of all checkbox values
                checkedValues[i] = $(this).parents('.dt-checkbox-container').find(":checkbox:checked").map(function () {
                  return $.fn.dataTable.util.escapeRegex(this.value);
                })
                  .get();

                // Add class to parent for styling purposes
                if ($(this).is(':checked')) {
                  $(this).parent().addClass('checked');
                } else {
                  $(this).parent().removeClass('checked');
                }

                // Convert array to string for regex search
                searchString[i] = checkedValues[i].join('|');
              });

            // create file-type filter options from column data
            // @urb generate server-side?
            $column.data().unique().sort().each(function (d) {
              let colValue = d.replace(/(<([^>]+)>)/gi, "").trim().split(',');
              colValue.forEach(function (item) {
                if (item !== '' && $.inArray(item, filterOptions[i]) === -1) {
                  filterOptions[i].push(item.trim());
                  $container[i].append('<label><input type="checkbox" class="dt-checkbox" value="' + item + '" />' + item + '</label>');
                }
              });
            });

            // Toggle filters on click event
            $colHeader.on('click', function (e) {

              if (!$container[i].is(e.target) && $container[i].has(e.target).length === 0) {
                $container[i].toggleClass('hidden');
              }
            });

            // file-type filter containers button
            $('button', $container[i])
              .appendTo($container[i]) // reposition btn to the end
              .on('click', function () {
                if (typeof searchString[i] !== 'undefined') {
                  // function $column().search( input [, regex[ , smart[ , caseInsen ]]] )
                  $column.search(searchString[i], true, false, true).draw();
                  $container[i].addClass('hidden');
                }
              });

            // listen for click event for closing the file-type container
            $(document).click(function (e) {
              if (
                // Filters are visible
                !$container[i].hasClass('hidden') &&
                // Click outside TH (filters are inside TH; target of the click isn't the TH nor a descendant)
                !$colHeader.is(e.target) &&
                $colHeader.has(e.target).length === 0) {
                $container[i].addClass('hidden');
              }
            });
          });
        },
        /**
         * handle case when more items than defined in datatable option lengthMenu
         */
        drawCallback: function () {
          // Change text of button based on whether there is more data available
          let showAll = this.parent().next().children();
          let dTable = this.api().page;

          if (this.api().rows({ search: 'applied' }).count() > defaultOptions.lengthMenu[0]) {
            showAll.removeClass('hidden');

            if (dTable.info().pages > 1) {
              showAll.text(showAll.data('t-show_all')).click(function () {
                dTable.len(-1).draw('page');
              });
            } else {
              showAll.text(showAll.data('t-show_less')).click(function () {
                dTable.len(defaultOptions.lengthMenu[0]).draw('page');
              });
            }
          } else {
            showAll.addClass('hidden');
          }
        }
      });

      // set table-wrapper elements min-height css property regarding the file-type-filter container
      /* let $tableWrapper = $(this).parents('.table-wrapper');
      let tableWrapperHeight = $('.dt-checkbox-container', this).outerHeight() + 70;
      $tableWrapper.css('min-height', tableWrapperHeight); */

    });
  };

  /**
   * setup ajax links and modal view for protected file download-links
   */
  const initializeAjaxLinks = () => {
    let $modal, $links;
    const AJAX_LINK_SELECTOR = 'a.file-link.use-ajax',
      MODAL_SELECTOR = '#protected-downloads-lightbox';

    // click handler function
    const handleClick = (e) => {
      e.preventDefault();
      const $el = $(e.currentTarget);

      const url = $el.attr('href');

      try {
        API.pull(url, null, null, 'text').then(resp => {
          // set response (html) as content
          $('.lightbox__content', $modal).html(resp);
          setupModal($modal);
          $modal.foundation('open');
        });

      } catch (error) {
        console.error(error);
      }
    };


    // get ajax links
    $links = $('.paragraph-download-area').find(AJAX_LINK_SELECTOR);

    if ($links.length) {

      // get modal container
      $modal = $(MODAL_SELECTOR);

      if ($modal.length) {

        $links.on('click', handleClick);
      } else {
        console.error('no modal container found with', MODAL_SELECTOR)
      }
    }
  };

  /**
   * setup links that open iframe in modal
   */
  const initializeModalLinks = () => {
    let $modal, $links;
    const DATA_OPEN_LINK_SELECTOR = 'a.file-link[data-open]',
      MODAL_SELECTOR = '#protected-downloads-lightbox';

    // click handler function
    const handleClick = (e) => {
      e.preventDefault();
      const $el = $(e.currentTarget);

      const src = $el.attr('href');
      setupIFrameModal($modal, src);
    };


    // get links with data-open attribute
    $links = $('.paragraph-download-area').find(DATA_OPEN_LINK_SELECTOR);

    if ($links.length) {

      // get modal container
      $modal = $(MODAL_SELECTOR);

      if ($modal.length) {

        $links.on('click', handleClick);
      } else {
        console.error('no modal container found with', MODAL_SELECTOR)
      }
    }
  };

  /**
   * initialize modal containers elements
   * - form labels
   * - select fields
   * - bind handler functions to form buttons and modal close 
   * 
   * @deprecated
   * @param {*} $modal jquery modal object 
   */
  const setupModal = ($modal) => {
    let $btnDownload, $btnDecline, $form;

    const FORM_SELECTOR = "#form-protected-file-disclaimer",
      BTN_DOWNLOAD_SELECTOR = "#form-protected-file-disclaimer-btn-download",
      BTN_DECLINE_SELECTOR = "#form-protected-file-disclaimer-btn-decline";

    const handleDownload = (e) => {
      console.debug('impl. download');

      let params = $form.serializeArray();

      console.debug(Array.from(params.entries()));

      $modal.foundation('close');
    };
    const handleDecline = () => {
      console.debug('impl. decline');
      $modal.foundation('close');
    };
    const handleClose = () => {
      // unbind events
      $btnDownload.off('click');
      $btnDecline.off('click');
      $modal.off('closed.zf.reveal');
    };



    if ($modal.length) {

      $form = $modal.find(FORM_SELECTOR);

      // init input labels
      FormElement.initFormLabels($modal);
      // initialize select elements
      FormElement.initSelectElements($modal);

      $btnDownload = $(BTN_DOWNLOAD_SELECTOR),
        $btnDecline = $(BTN_DECLINE_SELECTOR);

      // bind event handler to buttons
      $btnDownload.length && $btnDownload.on('click', handleDownload);
      $btnDecline.length && $btnDecline.on('click', handleDecline);
      // handle modal close event
      $modal.on('closed.zf.reveal', handleClose);
    }


  };

  const setupIFrameModal = ($modal, src) => {
    const $iframe = $('iframe', $modal);


    // set modal size to iframe content heigth
    $modal.on('open.zf.reveal', function () { 
    $iframe.on('load', function () {

      const contentHeight = $iframe.contents().height();
      $modal.height(`${contentHeight}px`)

      
    })
  })


    if ($iframe.length) {
      $iframe.attr('src', src)
    }
  };

  return {
    init: () => {
      initializeDatatables();
      initializeAjaxLinks();
      initializeModalLinks();
    }
  }
})(DataTable, $);

export default DownloadsTable;
