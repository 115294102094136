import $ from 'jquery';
import 'what-input';
import SiteHeader from './site/site-header';
import StickySidebar from './site/sticky-sidebar';
import Common from './site/common';
import MainMenu from './site/main-menu';
import RotatingBanner from './site/rotating-banner';
import Breadcrumbs from './site/breadcrumbs';
import XColumnTeasers from './site/x-column_teasers';
import Gallery from './site/gallery';
import VideoUtils from './site/video-utils';
import TextMedia from './site/text-media';
import CardSlider from './site/card-slider';
import DownloadsTable from './site/downloads-table';
import FormElement from './site/form-element';
import Forms from './site/forms';




// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
import FileSearchFilter from './site/file-search-filter';
import FileSearchResult from './site/file-search-result';
import MainSpinner from './site/spinner';
import GeneralSearch from './site/general-search';
import LocationMap from './site/map';
import AnchorLinks from './site/anchor-links';
import ProductHS from './site/products-hs';
import ProtectedDownloads from './site/protected-downloads';
import Downloadcenter from './site/downloadcenter';
import ProductInformation from './site/product-information';

$(document).foundation();



// initialize scripts
window.addEventListener('DOMContentLoaded', (event) => {
    
    Common.init();
    MainMenu.init();
    StickySidebar.init();
    AnchorLinks.init();
    SiteHeader.init();
    Breadcrumbs.init();
    RotatingBanner.init();
    TextMedia.init();
    XColumnTeasers.init();
    VideoUtils.init();
    Gallery.init();
    CardSlider.init();
    DownloadsTable.init();
    FormElement.init();
    Forms.init();
    FileSearchFilter.init();
    FileSearchResult.init();
    GeneralSearch.init();
    LocationMap.init(); 
    ProductHS.init();
    ProtectedDownloads.init();
    Downloadcenter.init();
    ProductInformation.init();

    MainSpinner.initialize('#main-spinner');
});

