import $ from 'jquery';
import 'jquery-once';
import VideoUtils from './video-utils';

/**
 * gallery 
 * 
 * slider slick  
 * lightbox foundation reveal
 * 
 * 
 */

const Gallery = (function () {
  'use strict';

  // slick slider default options
  let defaultOptions = {
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    slide: '.field__item',
    dots: true,
  }

  const initialize = function () {

    // setting item inner width
    $('.paragraph-gallery .gallery.with-lightbox .video, .gallery.lightbox-slider .video').once('gallerySlide').each(function () {
      $(this).parent('.item-inner').css('width', '100%');
    });

    let $galleries = $('.paragraph-gallery');

    $galleries.each(function () {

      let options,
        $gallery = $(this),
        dataOptions = $gallery.data('slick');

      // check if slick data attribute exists and create options
      if (dataOptions) {
        options = { ...defaultOptions, ...dataOptions }

      } else {
        options = defaultOptions;
      }


      if ($gallery.hasClass('paragraph-gallery--with-thumbnails')) {

        initializeGalleryWithThumbnails(options, $gallery);
      } else {

        initializeGalleryAndLightbox(options, $gallery);
      }
    })
  };

  /**
   * initialize gallery and lightbox sliders
   * @param {*} options slick slider options
   * @param {*} $context jquery object
   */
  const initializeGalleryAndLightbox = function (options, $context) {

    let $slider, $lightbox_slider;
    // Paragraph: Gallery
    $('.gallery', $context).once('gallerySlider').each(function () {
      $slider = $(this);

      // initialize slider only if more than 1 elements
      if ($('.field__item', $slider).length > 1) {

        // init gallery slider
        $slider.slick({
          ...options,
          ...{ appendArrows: $slider.parents('.paragraph-gallery__content') }
        });
      }

      // handle lightbox events
      $('#' + $slider.attr('id') + '-lightbox')
      // handle open lightbox event
        .on('open.zf.reveal', function () {
          $lightbox_slider = $('.lightbox__content', this);

          // initialize lightbox slider if more than 1 elements
          if ($('.field__item', $lightbox_slider).length > 1) {
            if ($lightbox_slider.is('.slick-initialized')) {
              // go to active slide of gallery
              $lightbox_slider.slick('slickGoTo', $slider.slick('slickCurrentSlide'), true);
            }
            else {
              // init lightbox slider
              $lightbox_slider.slick({
                ...options,
                ...{ asNavFor: $slider } // also navigate gallery slides
              }).slick('slickGoTo', $slider.slick('slickCurrentSlide'), true);
            }
          }
        })
        // handle on closing the lightbox
        .on('closed.zf.reveal', function () {
          //$slider.css('opacity', 0);

          // stop video in lightbox
          $('iframe', $lightbox_slider).each(function () {
            VideoUtils.stopIframeVideo($(this));
          });

          setTimeout(function () {
            if ($slider.is('.slick-initialized')) {

              $slider.slick('refresh');
              $slider.css('opacity', '');
            }
          }, 200);
        });
    });
  };

  /**
   * initialize gallery slider with thumbnails
   */
  const initializeGalleryWithThumbnails = function (options, context) {
    // Paragraph: Gallery With Thumbnails
    $('.gallery', context).once('gallerySlider').each(function () {
      var slider = $(this);
      var slider_nav = slider.next('.gallery-navigation');

      if ($('.field__item', slider).length > 1) {
        slider.slick(
          {
            ...options,
            ...{
              dots: false,
              fade: true,
              arrows: false,
              asNavFor: slider_nav
            } // also navigate gallery slides
          });

        slider_nav.slick(
          {
            ...options,
            ...{
              slidesToShow: 3,
              asNavFor: slider,
              focusOnSelect: true,
              centerMode: true,
              centerPadding: 0
            }
          }
        );

        if ($('.field__item', slider_nav).length <= 3) {
          slider_nav.addClass('not-scrollable');
        }

        $('#' + slider.attr('id') + '-lightbox')
          .on('open.zf.reveal', function () {
            var lightbox_slider = $(this);
            if (lightbox_slider.is('.slick-initialized')) {
              lightbox_slider.slick('slickGoTo', slider.slick('slickCurrentSlide'), true);
            }
            else {
              lightbox_slider.slick({
                ...options,
                ...{
                  initialSlide: slider.slick('slickCurrentSlide')
                }

              });
            }
          });
      }
    });
  };


  return {
    init: initialize
  }


})($);

export default Gallery;
