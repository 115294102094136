import $ from 'jquery';
import { v1 as uuidv1 } from 'uuid';
import FormElement from './form-element'

/**
 * Forms related logic
 */
const Forms = (() => {

    /**
   * return form related logic impl.
   * 
   * TODO:
   * @urb max count of return items
   */
    const initReturnForm = () => {

        let $itemFieldgroupReplica, $form = $('form#form-return'), $addBtn, itemsCount = 1, itemsMaxCount = 10;

        if ($form.length) {

            /**
             * create and prepare new item fieldgroup 
             * @param {*} $replica 
             * @returns 
             */
            let createFieldGroup = ($replica) => {
                // clone replica
                let $copy = $replica.clone();

                // set id (return-item-[timestamp])
                $copy.attr('id', `return-item-${uuidv1()}`);

                // set visibility
                $copy.removeClass('hide')
                // enable inputs
                $('input', $copy).each((idx, input) => {
                    $(input).removeAttr('disabled');
                });

                return $copy;
            };

            /**
             * delete handler removes fieldGroup 
             * @param {*} $fieldGroup 
             */
            let removeFieldGroup = ($fieldGroup) => {
                $fieldGroup.remove();
                itemsCount--;

                // check for max items count
                if (itemsCount < itemsMaxCount) {
                    $addBtn.removeAttr('disabled')
                }
            };





            // get add item btn 
            $addBtn = $('#form-return-button-add', $form);

            $addBtn.on('click', function () {

                // check for max items count
                if (itemsCount < itemsMaxCount) {

                    // get item fieldgroup replica
                    if (!$itemFieldgroupReplica) {

                        $itemFieldgroupReplica = $('#return-item-fieldgroup-replica:hidden', $form);
                    }
                    // create  fieldgroup copy
                    let $itemFieldgroupCopy = createFieldGroup($itemFieldgroupReplica);

                    // bind handler to delete button
                    $('.button-delete-item', $itemFieldgroupCopy).on('click', removeFieldGroup.bind(null, $itemFieldgroupCopy));

                    // get last fieldgroup
                    let $lastFieldGroup = $(':not(#return-item-fieldgroup-replica).return-item-fieldgroup:last', $form);

                    // append copy after last fieldgroup
                    $itemFieldgroupCopy.insertAfter($lastFieldGroup);

                    itemsCount++;

                    // check max items
                    if (itemsCount == itemsMaxCount) {

                        // disable add button
                        $addBtn.attr('disabled', 'disabled')

                        // TODO: display some message?
                    }
                }

            });

            // initialize radios with dependent inputs
            FormElement.setupDependentElements($form);

            // alternative pick up address
            // enable/disable input fields
            $('details.pick-up-address', $form).on("toggle", (ev) => {
                if (ev.target.open) {
                    $('.form-item > input, .form-item > select', $(ev.target)).removeAttr('disabled')
                } else {
                    $('.form-item > input, .form-item > select', $(ev.target)).attr('disabled', 'disabled');
                }
            });

        };
    };

    const initCustomerSurveyForm = () => {

        const $form = $('form#form-customer-survey');

        if ($form.length) {
            FormElement.setupDependentElements($form);

            // TODO: @urb do we need that?
            // setup sticky header

            // get table
            //const $table = $('#edit-survey-questions-table');





        }



    };


    /**
       * ebl form 
       * 
       * TODO:
       * @urb 
       *  - max count of return items?
       *  - reindexing needed? placeholder text etc.
       */
    const initEBLForm = () => {

        let $item, $itemClone, $form = $('form#form-ebl'), $addBtn, $deleteBtn, itemsCount = 1, itemIdx = 1, itemsMaxCount = 10;


        if ($form.length) {

            /**
             * create and prepare new item 
             * @returns 
             */
            let createItem = () => {
                // clone replica with eventhandler bindings
                let $copy = $itemClone.clone(true);

                // set inputs ids/names
                $('input, select', $copy).each((idx, el) => {

                    let name = $(el).prop('name'), newName, newId;

                    if (name.includes('licenseno')) {
                        newId = `items-licenseno-${itemIdx}`;
                        newName = `items[${itemIdx}][licenseno]`;
                    } else if (name.includes('gatewayid')) {
                        newName = `items[${itemIdx}][gatewayid]`;
                        newId = `items-gatewayid-${itemIdx}`;
                    } else if (name.includes('application')) {
                        newName = `items[${itemIdx}][application]`;
                        newId = `items-application-${itemIdx}`
                        
                        
                    } else { }
                    
                    $(el).prop('id', newId);
                    $(el).prop('name', newName);

                    // set labels for value too
                    $(el).prev().prop('for', newId);

                });

                return $copy;
            };


            /**
             * add handler adds new item 
             * @param {*} $itemGroup element to append the new group
             */
            let addItem = ($itemGroup) => {
                itemIdx++;

                // create item group copy
                let $itemNew = createItem();

                // append copy after current item group
                $itemNew.insertAfter($itemGroup);

                // init select element
                initSelects($('select', $itemNew));
                // init labels
                FormElement.initFormLabels($itemNew);

                itemsCount++;

                updateButtons();

            };

            /**
             * delete handler removes fieldGroup 
             * @param {*} $itemGroup 
             */
            let removeItem = ($itemGroup) => {

                $('select', $itemGroup).select2('destroy');
                $('.button-delete-item, .button-add-item', $itemGroup).off('click');

                $itemGroup.remove();
                itemsCount--;

                updateButtons();
            };

            /**
             * initialize select element with select2
             * @param {*} $select jquery select element
             * @param {*} options select2 options
             */
            let initSelects = ($selects) => {

                $selects.each((idx, sel) => {
                    $(sel).select2({
                        placeholder: $(sel).attr('placeholder')
                    });
                })
            };

            /**
             * delete input and select values
             * @param {*} $group 
             */
            let clearValues = ($group) => {
                $group.find('select, input:not(.button)').val('').attr('value', '');

                // delete options selected attribute
                let $options = $group.find('select option[selected]');
                $options.removeAttr('selected');
                    
                

                return $group;
            }

            let getLastIndex = () => {
                let currentLast;
                $form.find('input[id*="licenseno"]').each(function() {
                    let tmp = $(this).attr('id');
                    let lpart = tmp.split('-').pop();

                    if (!currentLast || currentLast < lpart) {
                        currentLast = lpart;
                    }

                });

                return currentLast;
            };


            /**
             * set items add/remove button to enabled or disabled regarding to the rules set
             */
            let updateButtons = () => {


                // check for max items count
                if (itemsCount < itemsMaxCount) {
                    $('.button-add-item', $form).removeAttr('disabled');
                }
                // enable all delete buttons for 1+ items
                if (itemsCount == 1) {
                    $('.button-delete-item', $form).attr('disabled', 'disabled');
                }

                // check max items
                if (itemsCount == itemsMaxCount) {

                    // disable add buttons
                    $('.button-add-item', $form).attr('disabled', 'disabled');

                    // TODO: display some message?
                }

                // enable all delete buttons for 1+ items
                if (itemsCount > 1) {
                    $('.button-delete-item', $form).removeAttr('disabled');
                }

            };



            // get add item button(s)
            $addBtn = $('.button-add-item', $form);
            $addBtn.on('click', function () {

                // get element to append the new group
                let $currentItemGroup = $(this).parents('.ebl-item-group')[0];


                // check for max items count
                if (itemsCount < itemsMaxCount) {
                    addItem($currentItemGroup);
                }

            });

            // get delete item button(s)
            $deleteBtn = $('.button-delete-item', $form);
            $deleteBtn.on('click', function () {

                let $currentItemGroup = $(this).parents('.ebl-item-group')[0];

                // bind handler to delete button
                removeItem($currentItemGroup);
            });


            // get first item group element
            let $items = $('.ebl-item-group', $form);
            $item = $items.first();
            itemsCount = $items.length;
            itemIdx = getLastIndex();

            // check and set itemsMaxCount
            itemsMaxCount &&= $item.data('items-max-count');

            // clone replica with eventhandler bindings
            // clear input values
            $itemClone = clearValues($item.clone(true));

            // init select(s)
            let $selects = $('select', $items);
            initSelects($selects);
            updateButtons();

        };
    };


    return {
        init: () => {
            initReturnForm(),
                initCustomerSurveyForm(),
                initEBLForm();
        }
    }

})();

export default Forms;