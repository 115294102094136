import $ from 'jquery';
import 'jquery-once'
import Swiper from 'swiper';


/**
 * Anchor Links Menu related functionalies
 */
const AnchorLinks = (function ($, Swiper) {
  let $containerMenu, $targetElements;

  const MODULE_SELECTOR = ".anchor-links";
  const MENU_ELEMENT_TEMPLATE = "#anchor-links__item-tpl";
  const TARGET_ELEMENT_SELECTOR = "section.paragraph .anchor-id[data-name]";

  /**
   * create anchor links menu elements using <template>-tag defined in the markup
   * menu element is created only when it has a container with class=.anchor-id with data-name property set.
   * 
   * @param {*} container 
   */
  const createElements = (container) => {

    $containerMenu = $('ul', container);
    $containerMenu.html("");

    // get elements with data-name attribute
    $targetElements = $(TARGET_ELEMENT_SELECTOR);
    const $template = $(MENU_ELEMENT_TEMPLATE, container);
    const $tpl = $template.contents();


    $targetElements.each((idx, el) => {
      let $element = $(el);
      let $menuElement = $tpl.clone();
      let $anchorEl = $('a', $menuElement);


      $menuElement.attr('data-index', idx);
      $anchorEl.attr('data-hash', $element.data('id'));
      $anchorEl.attr('href', `#${$element.data('id')}`);
      $anchorEl.text($element.data('name'))

      $containerMenu.append($menuElement);
    });

  };


  const initialize = () => {

    const $container = $(MODULE_SELECTOR);

    if ($container.length) {

      createElements($container);

      $($container).once('anchorLinks').each(function () {

        var ON_SCREEN_OFFSET = 120;
        var DEBOUNCE_VALUE = 140;
        var FAST_SCROLL_TIMEOUT = 650;

        //NAVIGATION
        var InPageNavigation = function () {
          this.window = $(window);
          this.body = $('body');
          this.navParent = $container;
          this.navParentHeight = this.navParent.height();
          this.swiper = null;
          this.carousel = this.navParent.find('.swiper-container');
          this.bottomLine = null;
          this.placeholder = $(`${MODULE_SELECTOR}__placeholder`);
          this.navList = this.navParent.find('.swiper-wrapper');
          this.navListSlides = this.navList.find('.swiper-slide a');
          this.navCurrentPosition = 0;
          this.watchMenuState = this.toggleScrollWatch();
          this.scrollPos = 0;
          this.allTargets = $('.anchor-id[data-id]');
          this.scrollDirection = "";
        };

        InPageNavigation.prototype = {
          changeActiveItem: function (item) {
            if (item.length) {
              this.watchMenuState(false);
              item.parent().addClass('active').siblings('.swiper-slide').not(item).removeClass('active');
              this.updateBottomLinePosition(item);
              this.swiper.slideTo(item.parent().data("index"), 400);
            }
          },
          getOnScreenOffset: function () {
            return this.navParentHeight + ON_SCREEN_OFFSET;
          },
          scrollToSection: function (itemName) {
            var self = this;
            var anchor = $targetElements.filter(`[data-id=${itemName}]`).first();
            if (anchor.length) {
              var position = (anchor.offset().top + self.navParentHeight);
              self.watchMenuState(false);
              $("html, body").animate({ scrollTop: position }, 400, function () {
                setTimeout(function () {
                  self.toggleNavSticky(true);
                  self.watchMenuState(true);
                }, (self.window.width() <= 1024 ? DEBOUNCE_VALUE : 0));
              });
            }
          },
          registerChangeActiveItemEvent: function () {
            var self = this;
            this.navListSlides.off('click').on('click', function () {
              var $this = $(this);
              self.changeActiveItem($this);
              self.scrollToSection($this.data('hash'));
            });
          },
          updateBottomLinePosition: function (item) {
            var self = this;
            var width = item.get(0).getBoundingClientRect().width;
            var left = item.parent().position().left + this.navCurrentPosition;
            var transDurantion = 400;
            var transDelay = 100;
            var desktopDebounce = self.window.width() <= 1024 ? DEBOUNCE_VALUE : 0;
            // Trigger Animation
            this.bottomLine.removeClass('u-hidden').css({
              'width': `${width}px`,
              'transform': `translateX(${left}px)`,
              'transition-property': 'transform width',
              'transition-duration': `${transDurantion}ms`,
              'transition-delay': `${transDelay}ms`,
            });
            setTimeout(() => {
              self.watchMenuState(true);
            }, (transDurantion + transDelay + desktopDebounce));
          },
          getVisibleTarget: function () {
            var windowTop = this.window.scrollTop();
            var self = this;
            var checkIfTopOfElementIsVisible = function (rec) {
              return rec && (rec.top <= window.innerHeight) && ((rec.top - DEBOUNCE_VALUE) + rec.height >= 0);
            };

            return this.allTargets.filter(function () {
              var rec = this.getBoundingClientRect();
              var content = $(this).parent();
              var contentRec = null;
              if (content.length) {
                contentRec = content.get(0).getBoundingClientRect();
              }
              return windowTop >= ($(this).offset().top - self.getOnScreenOffset()) &&
                (checkIfTopOfElementIsVisible(rec) || checkIfTopOfElementIsVisible(contentRec));
            }).first();
          },
          changeActiveItemOnScroll: function () {
            var target = this.getVisibleTarget();
            if (!target.length && this.allTargets.length && !this.navParent.hasClass('is-sticky')) {
              target = this.allTargets.first();
            }
            if (target.length) {
              this.watchMenuState(false);
              var slide = this.navListSlides.filter(function () {
                return this.getAttribute("data-hash") === target.attr("data-id");
              });
              if (slide.length) {
                this.changeActiveItem(slide);
              } else {
                this.watchMenuState(true);
              }
            }
          },
          initCarousel: function () {
            var self = this;
            var $prevBtn = $('.swiper-button__prev', $container);
            var $nextBtn = $('.swiper-button__next', $container);
            var $currentFirstEl, $currentLastEl;

            /**
             * update navigation controls prev and next
             */
            var updateNavigation = function () {

              // update first and last visible elements
              $currentFirstEl = $('.swiper-slide-fully-visible', $containerMenu).first();
              $currentLastEl = $('.swiper-slide-fully-visible', $containerMenu).last();

              // check if first visible slide has precedessors
              let prec = $currentFirstEl.prevAll('.swiper-slide');
              if (prec.length) {
                $prevBtn.show();
              } else {
                $prevBtn.hide();
              }
              // check if last visible slide has successors
              let suc = $currentLastEl.nextAll('.swiper-slide');
              if (suc.length) {
                $nextBtn.show();
              } else {
                $nextBtn.hide();
              }
            };


            this.swiper = new Swiper('.swiper-container', {
              freeMode: true,
              freeModeEnabled: true,
              freeModeMomentumRatio: 1,
              momentumRatio: 1,
              slidesPerView: "auto",
              watchSlidesProgress: true,
              on: {
                init: function () {
                  self.navList
                    .prepend("<li class='anchor-links__list-items__active-line u-hidden'/>");
                  self.carousel.removeClass("u-invisible");
                  self.bottomLine = $(document).find(".anchor-links__list-items__active-line");
                  self.swiper = this;
                  self.registerChangeActiveItemEvent();
                  self.updateMenu();
                  self.watchMenuState(true);
                  self.bottomLine.css('width', $("li.swiper-slide-active").width());


                  // bind events on custom navigation buttons
                  $nextBtn.on('click', function (e) {
                    self.swiper.slideNext();
                  });
                  $prevBtn.on('click', function (e) {
                    self.swiper.slidePrev();
                  });

                },
                slideChange: function () {
                  console.debug('slideChange')
                  updateNavigation();
                },
                slidesUpdated: function () {
                  console.debug('slidesUpdated')
                  updateNavigation();
                },
                snapIndexChange: function () {
                  console.debug('snapIndexChange')
                  updateNavigation();
                },
                reachBeginning: function () {
                  console.debug('reachBeginning')
                  $prevBtn.hide();
                },
                reachEnd: function () {
                  console.debug('reachEnd')
                  $nextBtn.hide();
                },
              }
            });
          },
          toggleNavSticky: function (isSticky) {
            if (isSticky) {
              this.navParent.addClass('is-sticky');
              this.placeholder.removeClass('u-hidden');
              this.body.addClass('anchor-sticky');
            } else {
              this.navParent.removeClass('is-sticky');
              this.bottomLine.addClass('u-hidden');
              this.placeholder.addClass('u-hidden');
              this.body.removeClass('anchor-sticky');
              this.swiper.slideTo(0, 400);
            }
          },
          updateMenu: function () {
            var self = this;
            if (!this.navParent.hasClass('is-sticky') && this.navParent.get(0).getBoundingClientRect().top <= 0) {
              this.toggleNavSticky(true);
            }
            if (this.navParent.hasClass('is-sticky') && this.placeholder.get(0).getBoundingClientRect().top >= 0) {
              this.toggleNavSticky();
            }

            //Wait if fast scroll
            setTimeout(function () {
              if (self.navParent.hasClass('is-sticky') && self.window.scrollTop() <= self.getOnScreenOffset()) {
                self.toggleNavSticky();
              }
            }, FAST_SCROLL_TIMEOUT);

            this.changeActiveItemOnScroll();
          },
          updateMenuOnHashChange: function ($this) {
            var hashVal = window.location.hash;
            if (hashVal) {
              var slide = $this.navListSlides.filter(function () {
                return encodeURI(this.getAttribute("href")) === hashVal;
              });
              if (slide.length) {
                $this.changeActiveItem(slide);
                $this.scrollToSection(slide.data('hash'));
              }
            }
          },
          toggleScrollWatch: function () {
            var updateMenu = this.updateMenu.bind(this);

            return function (toggle) {
              if (toggle) {
                this.window.on("scroll", updateMenu);
              } else {
                this.window.off("scroll", updateMenu);
              }
            };
          },
          getScrollDirection: function () {
            var newTop = window.pageYOffset;
            this.scrollDirection = newTop >= this.scrollPos ? "down" : "up";
            this.scrollPos = newTop;
          },
          init: function () {
            if (this.navParent.length) {
              this.initCarousel();
              var self = this;
              this.window.on('hashchange', function () {
                self.updateMenuOnHashChange(self);
              });
              this.updateMenuOnHashChange(this);
            }
          }
        };

        var InPageNav = new InPageNavigation();

        InPageNav.init();


        // scroll to anchor on event onload
        window.addEventListener("load", (ev) => {
          if (window.location.hash) {
            InPageNav.scrollToSection(window.location.hash.replace('#', ''));
          }
        });

      });

    }


  };

  return {
    init: initialize
  }

})($, Swiper);


export default AnchorLinks;
